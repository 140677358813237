//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bgimgUrl2 } from '/src/utils/config.js';
	import {
		mapState,
		mapActions
	} from 'vuex'
	import {
		// getInfo,
		getStartExam,
		getExerciseAnswer
	} from '../../api'
	import {
		Toast
	} from "vant";
	import audioImg from '/src/components/audioAutoPlay.vue'
	export default {
		name: "cj",
		components: {
			audioImg
		},
		data() {
			return {
				bgimgUrl2:'',
				userInfo: {},
				radio: '',
				show: true,
				list: [],
				result: {},
				flag: true,
				examData: {},
				totaltime: '',
				timeFlag: false
			}
		},
		created() {
			this.bgimgUrl2 = bgimgUrl2;
			Toast.clear()
			this.getList()
			if (this.$route.query.id == '2') {
				this.flag = false
				this.get_ExerciseAnswer()
			} else {
				window.addEventListener('pagehide', this.beforeunloadHandler, false)
				this.flag = true
				this.get_Answer()
				// this.get_Info()
				this.get_StartExam()
			}

			if (this.$store.state.minutes.length !== 0 && this.$store.state.seconds.length !== 0) {
				this.totaltime = this.s_to_hs(3600 - (this.sec_to_time(this.$store.state.minutes + ':' + this.$store.state
					.seconds)))

			}

			if (this.$router.history.current.path === '/cj') {
				sessionStorage.removeItem('list')
				sessionStorage.removeItem('list1')
				sessionStorage.removeItem('list2')
				sessionStorage.removeItem('minutes')
				sessionStorage.removeItem('seconds')
			}


		},
		mounted() {
			Toast.clear()
		},
		methods: {
			// 跳转排名页面
			paiming() {
				this.$router.push('/pmchooseStatus')
			},
			...mapActions(['AsyncSetPoints', 'AsyncSetRank', 'AsyncSetTotaltime']),
			beforeunloadHandler(e) {
				if (this.$route.query.id == '1') {
					sessionStorage.removeItem('list')
					sessionStorage.removeItem('list1')
					sessionStorage.removeItem('list2')
					e.preventDefault()
					e.returnValue = '111111'
				}
			},
			// get_Info() {
			// 	getInfo({
			// 		token: localStorage.getItem('token')
			// 	}).then(res => {
			// 		this.userInfo = res.data.data
			// 	})
			// },
			get_StartExam() {
				let that = this;
				Toast.loading({
					message: '成绩加载中...',
					duration: 0,
					overlay: true
				})
				getStartExam().then(res => {
					setTimeout(() => {
						that.get_StartExam()
					},100)
					if (res.data.data.is_answer == 0) {
						Toast.clear()
						Toast.fail('您还未答题')
						localStorage.setItem('flag', '0')
						setTimeout(() => {
							this.$router.push('/')
						})
					} else {
						console.log(res)
						this.examData = res.data.data.examData
						let data = res.data.data.questions
						let arr = []
						data.forEach(item => {
							item.arr = []
							item.options.forEach(i => {
								if (i?.answer === '1') {
									item.arr.push(i.key)
								}
							})
							if (item.typedata !== '3') {
								item.flag = this.judgeResultFun(item.arr, item.answer)
							} else if (item.typedata === '3') {
								item.flag = this.judgeResultFun(item.answer, [item.judgedata])
							}
						})
						console.log(data)
						this.list = data
						this.timeFlag = true
						Toast.clear()
					}
				})
			},
			getList() {
				this.list = this.answerData.map(item => {
					if (item.typedata == 2) {
						return {
							id: item.id,
							title: item.title,
							answercontent: item.answercontent,
							options: item.options,
							answerKey: item.options.filter(item => item.answer),
							answerKeyArr: item.options.map(item2 => {
								if (item2.container) {
									return item2.key
								}
							}),
							yesArr: item.options.map(item2 => {
								if (item2.answer == 1) {
									return item2.key
								}
							}),
							flag: item.flag,
							typedata: item.typedata
						}
					} else if (item.typedata === 1) {
						return {
							id: item.id,
							title: item.title,
							answercontent: item.answercontent,
							options: item.options,
							answerKey: item.options.filter(item => item.answer),
							answerKeyArr: item.options.map(item2 => {
								return item2.activekey
							}),
							yesArr: item.options.map(item2 => {
								if (item2.answer == 1) {
									return item2.key
								}
							}),
							flag: item.flag,
							typedata: item.typedata
						}
					} else {
						return {
							id: item.id,
							title: item.title,
							answercontent: item.answercontent,
							options: item.options,
							answerKey: item.options.filter(item => item.answer),
							answerKeyArr: item.options.map(item2 => {
								return item2.activekey
							}),
							yesArr: item.options.map(item2 => {
								if (item2.answer == 1) {
									return item2.key
								}
							}),
							flag: item.flag,
							judgedata: item.judgedata,
							container: item.container,
							typedata: item.typedata
						}
					}
				})
			},
			get_Answer() {
				const data = this.list.map(item => {
					return {
						id: item.id,
						answer: this._.compact(item.options.map(i => i.activekey))
					}
				})
				this.list = data
			},
			judgeResultFun(arr1, arr2) {
				let flag = true
				if (arr1.length !== arr2.length) {
					flag = false
				} else {
					arr1.forEach(item => {
						if (arr2.indexOf(item) === -1) {
							flag = false
						}
					})
				}
				return flag
			},
			s_to_hs(s) {
				//计算分钟
				//算法：将秒数除以60，然后下舍入，既得到分钟数
				let h
				h = Math.floor(s / 60)
				//计算秒
				//算法：取得秒%60的余数，既得到秒数
				s = s % 60
				//将变量转换为字符串
				h += ''
				s += ''
				//如果只有一位数，前面增加一个0
				h = (h.length == 1) ? '0' + h : h
				s = (s.length == 1) ? '0' + s : s
				return h + ':' + s
			},
			sec_to_time(s) {
				let time = s
				var min = time.split(':')[0];
				var sec = time.split(':')[1];
				time = Number(min * 60) + Number(sec)
				return time
			},
			get_ExerciseAnswer() {
				let arr = this.$store.state.answerData.map(item => {
					let arr1 = []
					let arr2 = []
					if (item.typedata !== '3') {
						arr1 = this._.compact(item.options.map(i => i.activekey))
					} else if (item.typedata === '3') {
						if (item?.judgedata === '1') {
							if (item.container === '1') {
								arr2.push('1')
							} else {
								arr2.push('0')
							}
						} else {
							if (item.container === '0') {
								arr2.push('0')
							} else {
								arr2.push('1')
							}
						}
					}
					return {
						id: item.id,
						answer: [...arr1, ...arr2]
					}
				})
				getExerciseAnswer({
					data: JSON.stringify(arr)
				}).then(res => {
					this.examData = {
						points: res.data.data.points
					}
				})
			},
			removeHandler() {
				sessionStorage.removeItem('list')
				sessionStorage.removeItem('list1')
				sessionStorage.removeItem('list2')
			}
		},
		destroyed() {
			window.removeEventListener('pagehide', this.removeHandler)
			sessionStorage.removeItem('list')
			sessionStorage.removeItem('list1')
			sessionStorage.removeItem('list2')
		},
		computed: {
			...mapState(['answerData'])
		}
	}
